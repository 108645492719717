import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMensagemList() {
  // Use toast
  const toast = useToast()

  const refMensagemListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'titulo'},
    { key: 'conteudo_padrao', label:'Conteudo Whats' },
    { key: 'conteudo_reduzido', label:'Conteudo SMS' },
    { key: 'assunto_email', label:'Assunto email' },
    { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')

  const dataMeta = ref({})


  const refetchData = () => {
    refMensagemListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchMensagens = (ctx, callback) => {
    store
      .dispatch('app-mensagem/fetchMensagens', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching mensagem list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchMensagens,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refMensagemListTable,

    refetchData

  }
}
